<template>
  <div>
    <div class="head">
      <h2
        class="text-center top-text "
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of
        Nigerians
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <figure class="">
          <img
            src="../../assets/blog new/eyecarengonline_1628779252293258.jpg"
            alt=""
            width="100%"
            style="max-height: 32rem;width: 100%;object-fit: contain"
          />
        </figure>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class="body-1" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of
            Nigerians
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: July 19, 2021</p>
            </span>
          </div>
          <p class="">
            Carriers of Life, a blog dedicated to educating Nigerians on the
            best ways to live a healthy life, hosted Dr Rose Azuike on July 19,
            2021, as matters concerning optical care were discussed. The
            renowned eye optometrist joined the Carriers of Life team to discuss
            the core concepts and treatment procedures of glaucoma.
          </p>
          <p>
            Glaucoma, which gradually damages the optic nerves of the eye, is
            the second leading cause of irreversible blindness in the world. As
            of 2017, over 16% of the population in Nigeria were estimated to
            have glaucoma. A report has it that 1.8 million people in Nigeria
            over the age of 40 have been diagnosed with glaucoma, with 360,000
            of this number already blind as a result of the eye disease.
          </p>
          <p>
            As evident in the reports of people affected by the disease, the
            life-long glaucoma disease is prevalent in individuals forty years
            and above, and people who have had eye surgery. People suffering
            from hypertension are also at risk of glaucoma. The primary cause of
            this disease is increased eye pressure. However, other causes of
            glaucoma exist.
          </p>
          <p class="grey-text">
            “There are a number of causes of glaucoma, one common one is the
            increase in the pressure of the eye. Glaucoma is also caused when
            the nerves at the back of the eye are very sensitive to pressure.
            Someone could still have normal pressure and get glaucoma.
            Generally, anything that can cause an increase in the pressure of
            the eye and also cut off blood flow to the eye can cause glaucoma”,
            <span style="color:black">Dr Rose Azuike explained.</span>
          </p>
          <p>
            Dr Azuike who is also the managing director and principal
            optometrist at
            <span style="color:red">Kesona Eye Center, Lagos</span>, , further
            explained that glaucoma is one of the many illnesses in the world
            without a cure. However, she also did mention that years of research
            and medical trials by world medical experts, were not unfruitful, as
            a number of drugs have been developed for the treatment of glaucoma.
            One of the details she stressed was the bland difference between a
            disease that can be treated and one that can be cured. Diseases like
            glaucoma can only be managed with the use of medication which works
            to hinder further damages from happening.
          </p>
          <!-- <v-skeleton-loader
            type="card-avatar"
            v-if="loading === true"
            :loading="loading"
          ></v-skeleton-loader> -->
          <figure class="pt-8">
            <img
              src="../../assets/blog new/image (6).jpg"
              alt=""
              width="100%"
              class=""
              style="max-height: 21rem;object-fit: contain;"
            />
          </figure>

          <p class="grey-text">
            “Glaucoma is generally treated with eye drops, and in severe cases,
            surgery. These drugs do not cure glaucoma, they only stop the
            disease in its tracks and keep it from causing more damage. It is a
            lifelong sickness”,<span style="color:black">
              Dr Azuike clarified.</span
            >
          </p>
          <p>
            One of the drugs used to cure glaucoma is Latanoprost, commonly
            called Xalatan. Xalatan, just like most eye drops, is thermally
            reactive and requires low temperatures to stay potent. Unopened
            Xalatan bottles are stored at temperatures between 2ºC to 8ºC. Dr
            Azuike stated that due to problems with electricity, storing Xalatan
            in the eye centre she manages poses quite difficult. She explained
            that in a bid to prevent cases of drug wastage, or loss of potency,
            many times she refers her patients to pharmacists with a written
            prescription for the drug.
          </p>
          <figure class="pt-8">
            <img
              src="../../assets/blog new/image (4).jpg"
              alt=""
              width="100%"
              class="pb-4"
              style="max-height: 28rem;object-fit: contain"
            />
          </figure>

          <p>
            Drug wastage is one of the problems that have plagued the
            pharmaceutical and health sector for decades. One of the challenges
            associated with supply chain management of thermally reactive drugs
            is the use of ineffective cold chain equipment. Because of the lack
            of proper cold chain equipment in health facilities, drug wastage
            persists as one of the problems of the health care sector in
            Nigeria, as valuable drugs lose potency when exposed to
            inappropriate temperature and other poor storage conditions.
          </p>
          <p class="grey-text">
            “My understanding in the storage of drugs is that if a drug is not
            stored at the recommended temperature, it is going to affect the
            potency of the drug. The main constituents of the drug can be
            affected, and it can become denatured. If a drug is denatured, it
            wouldn't produce the needed effects in the body. In the case of
            glaucoma, you don’t want an impotent drug because it means that the
            drug is not going to do what it ought to do'',
            <span style="color:black">the optometrist stressed.</span>
          </p>

          <v-skeleton-loader
            type="card-avatar"
            v-if="loading === true"
            :loading="loading"
          ></v-skeleton-loader>
          <video
            loop
            playsinline
            controls
            muted
            poster=""
            width="100%"
            height="100%"
            style="object-fit: contain"
            v-show="loaded"
            class="vid-lazy"
            id="vid-el"
          >
            <source
              src="https://res.cloudinary.com/dqqrgidob/video/upload/v1629275976/Video.Guru_20210809_153538984_u2x942.mp4"
              type="video/mp4"
            />
            Your browser does not support HTML video.
          </video>

          <p class="pt-5">
            To better the healthcare system in Nigeria and to tackle the problem
            of drug storage in Nigeria, better cold chain equipment have been
            developed by Nigerian and international organizations. Rechargeable
            Cold boxes like MOTE 50 developed by Gricd Integrated Services
            Limited(a Nigerian cold chain start-up) has been very effective in
            ensuring last mile storage of temperature-controlled products like
            glaucoma eye drops.
          </p>

          <p>
            With the growing adoption of these technologically advanced cold
            chain equipment in the Nigerian health sector, Glaucoma drugs like
            Xalatan can be better preserved and distributed more efficiently.
          </p>

          <p class="grey-text">
            <span style="color:black">Dr Azuike concluded by saying,</span>
            "Glaucoma is the most common cause of irreversible blindness in
            Africans. If you have been already diagnosed with glaucoma, and you
            have your eye drop, and medication, one important thing you should
            keep in mind is that storage is important. Proper treatment and
            storage of the glaucoma drug are important".
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{ hover }">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
// import img1 from '../../assets/images/Rectangle 25.png';
// import img2 from '../../assets/images/Rectangle 26.png';
// import img3 from '../../assets/images/image 11 (1).png';
import image4 from '../../assets/images/baby vaccination.jpg';
import image5 from '../../assets/images/covid oon deep oce.jpg';
import image6 from '../../assets/images/covid19-vaccine-waste.jpg';
// import image from '../../assets/images/Rectangle 27.png';
export default {
  data: () => ({
    loaded: false,
    loading: true,
    info: ['News', 'Health', 'Logistics'],
    rightSide: [
      {
        img: image4,
        content:
          'MOTE Temperature Monitoring Device Used to Store Vaccines For Immunization Services In Nigeria',
        date: 'June 13, 2021',
        link:
          '/blog/mote-temperature-monitoring-device-used-to-store-vaccines-for-immunization-services-in-nigeria',
      },
      {
        img: image5,
        content:
          'The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt New Cold Chain Equipment to Store Vaccines at Appropriate Temperatures',
        date: 'June 13, 2021',
        link:
          '/blog/the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures',
      },
      {
        img: image6,
        content:
          'Reduce the Frequency of Vaccine Waste With A New And Tested Cold Chain Equipment',
        date: 'June 13, 2021',
        link:
          '/blog/reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment',
      },
    ],
    lastCard: [
      {
        img: image4,
        content:
          'MOTE Temperature Monitoring Device Used to Store Vaccines For Immunization Services In Nigeria',
        date: 'June 13, 2021',
        link:
          '/blog/mote-temperature-monitoring-device-used-to-store-vaccines-for-immunization-services-in-nigeria',
      },
      {
        img: image5,
        content:
          'The Secret Of COVID-19 Vaccine Preservation: Health Workers Adopt New Cold Chain Equipment to Store Vaccines at Appropriate Temperatures',
        date: 'June 13, 2021',
        link:
          '/blog/the-secret-of-covid-19-vaccine-preservation:-health-workers-adopt-new-cold-chain-equipment-to-store-vaccines-at-appropriate-temperatures',
      },
      {
        img: image6,
        content:
          'Reduce the Frequency of Vaccine Waste With A New And Tested Cold Chain Equipment',
        date: 'June 13, 2021',
        link:
          '/blog/reduce-the-frequency-of-vaccine-waste-with-a-new-and-tested-cold-chain-equipment',
      },
    ],
    mySVG: require('../../assets/Logo/search.svg'),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://gricd.com/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Blog',
                item: 'https://gricd.com/blog',
              },
              {
                '@type': 'ListItem',
                position: 3,
                name:
                  'Dr Rose Azuike Speaks About Glaucoma and Its Effects on the lives of Nigerians',
                item:
                  'https://gricd.com/blog/dr-rose-azuike-speaks-about-glaucoma-and-its-effects-on-the-lives-of-nigerians',
              },
            ],
          },
        },
      ],
    };
  },
  created() {
    const readyHandler = () => {
      //   console.log(document.readyState);
      if (document.readyState == 'complete') {
        this.loading = false;
        this.loaded = true;
        document.removeEventListener('readystatechange', readyHandler);
      }
    };

    document.addEventListener('readystatechange', readyHandler);

    readyHandler(); // in case the component has been instantiated lately after loading
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == 'complete') {
        // this.loading = false;
        // this.loaded = true;
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    // padding: 3rem 0;
  }
}
a {
  text-decoration: none !important;
  color: black !important;
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
// p span {
//   text-decoration: underline;
//   color: #28a84a;
// }
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.date {
  p {
    font-size: 0.775rem !important;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
.grey-text {
  color: #676464;
}
</style>
